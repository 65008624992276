<template>
  <div>
    <b-form-group label-for="name">
      <template v-slot:label>
        Tên <span class="text-danger">*</span>
      </template>
      <validation-provider
        #default="{ errors }"
        name="Tên"
        rules="required"
      >
        <b-form-input
          id="name"
          v-model="targetDataSource.name"
          name="name"
          placeholder="Nhập tên"
          :state="getElementState(errors)"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <slot />
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label-for="status">
            <template v-slot:label>
              Trạng thái <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Trạng thái"
              rules="required"
            >
              <v-select
                v-model="targetDataSource.status"
                :options="statuses"
                :reduce="option => option.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label-for="orderNo">
            <template v-slot:label>
              Thứ tự
            </template>
            <validation-provider
              #default="{ errors }"
              name="Thứ tự"
            >
              <b-form-input
                id="orderNo"
                v-model="targetDataSource.orderNo"
                name="orderNo"
                placeholder="Nhập thứ tự"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { STATUSES } from '@/const/status'
import { Flag } from '@/const/flag'

export default {
  name: 'CategorySave',
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormInput,
    vSelect,
    BFormGroup,
  },
  props: {
    targetDataSource: {
      type: Object,
      default: () => ({
        name: '',
        code: '',
        status: Flag.ACTIVE,
        orderNo: null,
      }),
    },
  },
  computed: {
    statuses() {
      return STATUSES
    },
  },
  methods: {
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>
